import React from "react";
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../layouts/layout";

const Contact = ({ data: { hero } }) => {

    hero.buttons = (
        <div className="mt-8">
            <Link to="/locations">
            <button className="bg-transparent hover:bg-mane-yellow text-white text-xs font-semibold font-aktivcd hover:text-gray-800 uppercase py-3 px-8 border border-white hover:border-transparent tracking-tight mr-6">
                locations
            </button>
            </Link>
        </div>
    )


    return (

        <Layout hero={hero}>

            <Helmet>
                <script src="https://www.google.com/recaptcha/api.js" async defer ></script>
                <script>
                    {`
                    window.onSuccessfulResponse = () => {
                        document.getElementById('contact-form-submit').removeAttribute('disabled');
                    }
                    `}
                </script>
            </Helmet>



            <div className="container mx-auto mb-16 sm:mb-32 px-4">
                <div className="sm:flex items-center">

                    <div className="mb-10 lg:w-1/4 sm:mb-0 self-start">
                        <div className="p-6 border-shadow h-full border">
                            <div className="mb-4">
                                <h5 className="text-3xl dark-blue font-bold mb-4">Headquarters</h5>
                                <p className="soft-gray text-sm tracking-wider">Ashrafieh, Charles Malek Avenue</p>
                                <p className="soft-gray text-sm tracking-tight">Sole Center, 8th Floor, P.O. Box 165611</p>
                                <p className="soft-gray text-sm">Beirut, Lebanon</p>
                            </div>

                            <hr className="px-8" />

                            <div className="py-4 text-sm">
                                <div className="flex mb-2">
                                     <p className="w-16 font-bold">Tel:</p>
                                     <p className="">+961 (1) 215111</p>

                                </div>
                                <div className="flex mb-2">
                                    <p className="w-16 font-bold">Fax:</p>
                                    <p className=""> +961 (1) 335222</p>

                                </div>
                                <div className="flex mb-2">
                                    <p className="w-16 font-bold">Email: </p>
                                    <p className="">contacts@manenterprise.com</p>

                                </div>

                            </div>



                        </div>
                    </div>

                    <div className="flex-1 sm:ml-16 self-start">
                        <div className="mb-6">
                            <h2 className="text-3xl sm:text-5xl font-bold dark-blue leading-tight">Send us a message</h2>
                        </div>

                        <div className="w-full max-w-xl">
                            <p className="soft-gray text-sm">* indicates required field</p>
                            <Helmet>
                                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                            </Helmet>
                            <form className="bg-white pt-6 pb-8 mb-4" action="https://us-central1-mane-5e387.cloudfunctions.net/send-mail-from-contact-form" method="POST">
                                <h2 className="text-xl sm:text-xl font-bold dark-blue leading-tight mb-4">Your information</h2>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                        Full name*
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="name" id="username" type="text" required />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                        Email address*
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" name="email" type="email" required />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" for="country">
                                        Country
                                    </label>

                                    <div className="inline-block relative w-full">
                                        <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" name="country">
                                            <option>Select...</option>
                                            <option value="DZ" val="213">Algeria (+213)</option>
                                            <option value="AD" val="376">Andorra (+376)</option>
                                            <option value="AO" val="244">Angola (+244)</option>
                                            <option value="AI" val="1264">Anguilla (+1264)</option>
                                            <option value="AG" val="1268">Antigua &amp; Barbuda (+1268)</option>
                                            <option value="AR" val="54">Argentina (+54)</option>
                                            <option value="AM" val="374">Armenia (+374)</option>
                                            <option value="AW" val="297">Aruba (+297)</option>
                                            <option value="AU" val="61">Australia (+61)</option>
                                            <option value="AT" val="43">Austria (+43)</option>
                                            <option value="AZ" val="994">Azerbaijan (+994)</option>
                                            <option value="BS" val="1242">Bahamas (+1242)</option>
                                            <option value="BH" val="973">Bahrain (+973)</option>
                                            <option value="BD" val="880">Bangladesh (+880)</option>
                                            <option value="BB" val="1246">Barbados (+1246)</option>
                                            <option value="BY" val="375">Belarus (+375)</option>
                                            <option value="BE" val="32">Belgium (+32)</option>
                                            <option value="BZ" val="501">Belize (+501)</option>
                                            <option value="BJ" val="229">Benin (+229)</option>
                                            <option value="BM" val="1441">Bermuda (+1441)</option>
                                            <option value="BT" val="975">Bhutan (+975)</option>
                                            <option value="BO" val="591">Bolivia (+591)</option>
                                            <option value="BA" val="387">Bosnia Herzegovina (+387)</option>
                                            <option value="BW" val="267">Botswana (+267)</option>
                                            <option value="BR" val="55">Brazil (+55)</option>
                                            <option value="BN" val="673">Brunei (+673)</option>
                                            <option value="BG" val="359">Bulgaria (+359)</option>
                                            <option value="BF" val="226">Burkina Faso (+226)</option>
                                            <option value="BI" val="257">Burundi (+257)</option>
                                            <option value="KH" val="855">Cambodia (+855)</option>
                                            <option value="CM" val="237">Cameroon (+237)</option>
                                            <option value="CA" val="1">Canada (+1)</option>
                                            <option value="CV" val="238">Cape Verde Islands (+238)</option>
                                            <option value="KY" val="1345">Cayman Islands (+1345)</option>
                                            <option value="CF" val="236">Central African Republic (+236)</option>
                                            <option value="CL" val="56">Chile (+56)</option>
                                            <option value="CN" val="86">China (+86)</option>
                                            <option value="CO" val="57">Colombia (+57)</option>
                                            <option value="KM" val="269">Comoros (+269)</option>
                                            <option value="CG" val="242">Congo (+242)</option>
                                            <option value="CK" val="682">Cook Islands (+682)</option>
                                            <option value="CR" val="506">Costa Rica (+506)</option>
                                            <option value="HR" val="385">Croatia (+385)</option>
                                            <option value="CU" val="53">Cuba (+53)</option>
                                            <option value="CY" val="90392">Cyprus North (+90392)</option>
                                            <option value="CY" val="357">Cyprus South (+357)</option>
                                            <option value="CZ" val="42">Czech Republic (+42)</option>
                                            <option value="DK" val="45">Denmark (+45)</option>
                                            <option value="DJ" val="253">Djibouti (+253)</option>
                                            <option value="DM" val="1809">Dominica (+1809)</option>
                                            <option value="DO" val="1809">Dominican Republic (+1809)</option>
                                            <option value="EC" val="593">Ecuador (+593)</option>
                                            <option value="EG" val="20">Egypt (+20)</option>
                                            <option value="SV" val="503">El Salvador (+503)</option>
                                            <option value="GQ" val="240">Equatorial Guinea (+240)</option>
                                            <option value="ER" val="291">Eritrea (+291)</option>
                                            <option value="EE" val="372">Estonia (+372)</option>
                                            <option value="ET" val="251">Ethiopia (+251)</option>
                                            <option value="FK" val="500">Falkland Islands (+500)</option>
                                            <option value="FO" val="298">Faroe Islands (+298)</option>
                                            <option value="FJ" val="679">Fiji (+679)</option>
                                            <option value="FI" val="358">Finland (+358)</option>
                                            <option value="FR" val="33">France (+33)</option>
                                            <option value="GF" val="594">French Guiana (+594)</option>
                                            <option value="PF" val="689">French Polynesia (+689)</option>
                                            <option value="GA" val="241">Gabon (+241)</option>
                                            <option value="GM" val="220">Gambia (+220)</option>
                                            <option value="GE" val="7880">Georgia (+7880)</option>
                                            <option value="DE" val="49">Germany (+49)</option>
                                            <option value="GH" val="233">Ghana (+233)</option>
                                            <option value="GI" val="350">Gibraltar (+350)</option>
                                            <option value="GR" val="30">Greece (+30)</option>
                                            <option value="GL" val="299">Greenland (+299)</option>
                                            <option value="GD" val="1473">Grenada (+1473)</option>
                                            <option value="GP" val="590">Guadeloupe (+590)</option>
                                            <option value="GU" val="671">Guam (+671)</option>
                                            <option value="GT" val="502">Guatemala (+502)</option>
                                            <option value="GN" val="224">Guinea (+224)</option>
                                            <option value="GW" val="245">Guinea - Bissau (+245)</option>
                                            <option value="GY" val="592">Guyana (+592)</option>
                                            <option value="HT" val="509">Haiti (+509)</option>
                                            <option value="HN" val="504">Honduras (+504)</option>
                                            <option value="HK" val="852">Hong Kong (+852)</option>
        <option value="HU" val="36">Hungary (+36)</option>
                                            <option value="IS" val="354">Iceland (+354)</option>
                                            <option value="IN" val="91">India (+91)</option>
                                            <option value="ID" val="62">Indonesia (+62)</option>
                                            <option value="IR" val="98">Iran (+98)</option>
                                            <option value="IQ" val="964">Iraq (+964)</option>
                                            <option value="IE" val="353">Ireland (+353)</option>
                                            <option value="IL" val="972">Israel (+972)</option>
                                            <option value="IT" val="39">Italy (+39)</option>
                                            <option value="JM" val="1876">Jamaica (+1876)</option>
                                            <option value="JP" val="81">Japan (+81)</option>
                                            <option value="JO" val="962">Jordan (+962)</option>
                                            <option value="KZ" val="7">Kazakhstan (+7)</option>
                                            <option value="KE" val="254">Kenya (+254)</option>
                                            <option value="KI" val="686">Kiribati (+686)</option>
                                            <option value="KP" val="850">Korea North (+850)</option>
                                            <option value="KR" val="82">Korea South (+82)</option>
                                            <option value="KW" val="965">Kuwait (+965)</option>
                                            <option value="KG" val="996">Kyrgyzstan (+996)</option>
                                            <option value="LA" val="856">Laos (+856)</option>
                                            <option value="LV" val="371">Latvia (+371)</option>
                                            <option value="LB" val="961">Lebanon (+961)</option>
                                            <option value="LS" val="266">Lesotho (+266)</option>
                                            <option value="LR" val="231">Liberia (+231)</option>
                                            <option value="LY" val="218">Libya (+218)</option>
                                            <option value="LI" val="417">Liechtenstein (+417)</option>
                                            <option value="LT" val="370">Lithuania (+370)</option>
                                            <option value="LU" val="352">Luxembourg (+352)</option>
        <option value="MO" val="853">Macao (+853)</option>
                                            <option value="MK" val="389">Macedonia (+389)</option>
                                            <option value="MG" val="261">Madagascar (+261)</option>
                                            <option value="MW" val="265">Malawi (+265)</option>
                                            <option value="MY" val="60">Malaysia (+60)</option>
                                            <option value="MV" val="960">Maldives (+960)</option>
                                            <option value="ML" val="223">Mali (+223)</option>
                                            <option value="MT" val="356">Malta (+356)</option>
                                            <option value="MH" val="692">Marshall Islands (+692)</option>
                                            <option value="MQ" val="596">Martinique (+596)</option>
                                            <option value="MR" val="222">Mauritania (+222)</option>
                                            <option value="YT" val="269">Mayotte (+269)</option>
                                            <option value="MX" val="52">Mexico (+52)</option>
        <option value="FM" val="691">Micronesia (+691)</option>
                                            <option value="MD" val="373">Moldova (+373)</option>
                                            <option value="MC" val="377">Monaco (+377)</option>
                                            <option value="MN" val="976">Mongolia (+976)</option>
                                            <option value="MS" val="1664">Montserrat (+1664)</option>
                                            <option value="MA" val="212">Morocco (+212)</option>
                                            <option value="MZ" val="258">Mozambique (+258)</option>
                                            <option value="MN" val="95">Myanmar (+95)</option>
                                            <option value="NA" val="264">Namibia (+264)</option>
                                            <option value="NR" val="674">Nauru (+674)</option>
                                            <option value="NP" val="977">Nepal (+977)</option>
                                            <option value="NL" val="31">Netherlands (+31)</option>
        <option value="NC" val="687">New Caledonia (+687)</option>
                                            <option value="NZ" val="64">New Zealand (+64)</option>
                                            <option value="NI" val="505">Nicaragua (+505)</option>
                                            <option value="NE" val="227">Niger (+227)</option>
                                            <option value="NG" val="234">Nigeria (+234)</option>
                                            <option value="NU" val="683">Niue (+683)</option>
                                            <option value="NF" val="672">Norfolk Islands (+672)</option>
                                            <option value="NP" val="670">Northern Marianas (+670)</option>
                                            <option value="NO" val="47">Norway (+47)</option>
                                            <option value="OM" val="968">Oman (+968)</option>
                                            <option value="PW" val="680">Palau (+680)</option>
        <option value="PA" val="507">Panama (+507)</option>
                                            <option value="PG" val="675">Papua New Guinea (+675)</option>
                                            <option value="PY" val="595">Paraguay (+595)</option>
                                            <option value="PE" val="51">Peru (+51)</option>
                                            <option value="PH" val="63">Philippines (+63)</option>
                                            <option value="PL" val="48">Poland (+48)</option>
                                            <option value="PT" val="351">Portugal (+351)</option>
                                            <option value="PR" val="1787">Puerto Rico (+1787)</option>
                                            <option value="QA" val="974">Qatar (+974)</option>
                                            <option value="RE" val="262">Reunion (+262)</option>
        <option value="RO" val="40">Romania (+40)</option>
                                            <option value="RU" val="7">Russia (+7)</option>
                                            <option value="RW" val="250">Rwanda (+250)</option>
                                            <option value="SM" val="378">San Marino (+378)</option>
                                            <option value="ST" val="239">Sao Tome &amp; Principe (+239)</option>
                                            <option value="SA" val="966">Saudi Arabia (+966)</option>
                                            <option value="SN" val="221">Senegal (+221)</option>
                                            <option value="CS" val="381">Serbia (+381)</option>
                                            <option value="SC" val="248">Seychelles (+248)</option>
                                            <option value="SL" val="232">Sierra Leone (+232)</option>
        <option value="SG" val="65">Singapore (+65)</option>
                                            <option value="SK" val="421">Slovak Republic (+421)</option>
                                            <option value="SI" val="386">Slovenia (+386)</option>
                                            <option value="SB" val="677">Solomon Islands (+677)</option>
                                            <option value="SO" val="252">Somalia (+252)</option>
                                            <option value="ZA" val="27">South Africa (+27)</option>
                                            <option value="ES" val="34">Spain (+34)</option>
                                            <option value="LK" val="94">Sri Lanka (+94)</option>
                                            <option value="SH" val="290">St. Helena (+290)</option>
        <option value="KN" val="1869">St. Kitts (+1869)</option>
                                            <option value="SC" val="1758">St. Lucia (+1758)</option>
                                            <option value="SD" val="249">Sudan (+249)</option>
                                            <option value="SR" val="597">Suriname (+597)</option>
                                            <option value="SZ" val="268">Swaziland (+268)</option>
                                            <option value="SE" val="46">Sweden (+46)</option>
                                            <option value="CH" val="41">Switzerland (+41)</option>
                                            <option value="SI" val="963">Syria (+963)</option>
                                            <option value="TW" val="886">Taiwan (+886)</option>
        <option value="TJ" val="7">Tajikstan (+7)</option>
                                            <option value="TH" val="66">Thailand (+66)</option>
                                            <option value="TG" val="228">Togo (+228)</option>
                                            <option value="TO" val="676">Tonga (+676)</option>
                                            <option value="TT" val="1868">Trinidad &amp; Tobago (+1868)</option>
                                            <option value="TN" val="216">Tunisia (+216)</option>
                                            <option value="TR" val="90">Turkey (+90)</option>
                                            <option value="TM" val="7">Turkmenistan (+7)</option>
        <option value="TM" val="993">Turkmenistan (+993)</option>
                                            <option value="TC" val="1649">Turks &amp; Caicos Islands (+1649)</option>
                                            <option value="TV" val="688">Tuvalu (+688)</option>
                                            <option value="UG" val="256">Uganda (+256)</option>
                                            <option value="GB" val="44">UK (+44)</option>
                                            <option value="UA" val="380">Ukraine (+380)</option>
                                            <option value="AE" val="971">United Arab Emirates (+971)</option>
                                            <option value="UY" val="598">Uruguay (+598)</option>
                                            <option value="US" val="1">USA (+1)</option>
                                            <option value="UZ" val="7">Uzbekistan (+7)</option>
                                            <option value="VU" val="678">Vanuatu (+678)</option>
                                            <option value="VA" val="379">Vatican City (+379)</option>
                                            <option value="VE" val="58">Venezuela (+58)</option>
                                            <option value="VN" val="84">Vietnam (+84)</option>
                                            <option value="VG" val="84">Virgin Islands - British (+1284)</option>
                                            <option value="VI" val="84">Virgin Islands - US (+1340)</option>
        <option value="WF" val="681">Wallis &amp; Futuna (+681)</option>
                                            <option value="YE" val="969">Yemen (North)(+969)</option>
                                            <option value="YE" val="967">Yemen (South)(+967)</option>
                                            <option value="ZM" val="260">Zambia (+260)</option>
                                            <option value="ZW" val="263">Zimbabwe (+263)</option>
                                    </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>

                                </div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                                        Phone number
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="tel" name="phone" />
                                </div>
                                <h2 className="text-xl sm:text-xl font-bold dark-blue leading-tight mt-12 mb-4">Company Information</h2>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
                                        Company name
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" name="companyName"/>
                                </div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="jobTitle">
                                        Job title
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" name="jobTitle"/>
                                </div>


                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                                        Message
                                    </label>
                                    <textarea rows="8" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" placeholder="your message" name="message">
                                    </textarea>
                                </div>

                               
                                <div className="flex mb-4 justify-end">
                                   <div className="g-recaptcha" data-sitekey="6LfKI-gaAAAAAB_HlbzeJAX2f8n-m9DALcViiYMd" data-callback="onSuccessfulResponse"></div>
                                </div>

                                <div className="flex mb-4 justify-end">
                                   <button className="bg-mane-yellow hover:bg-white soft-gray text-xs font-semibold font-aktivcd hover:text-gray-800 uppercase py-3 px-8 border border-gray hover:border-mane-yellow tracking-tight" id="contact-form-submit" disabled="true">
                                       submit
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>

    )};

export const query = graphql`
    query Contact {
      hero: contentfulContact (slug: {eq: "contact"}) {
        heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
        heroHeading
        heroHeadingTwo
      }
    }
`;


export default Contact;
